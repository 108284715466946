$(window).on('load', function () {

    $('#overlay').slideDown();

    $(".lb_section").addClass("lb_open");

    $(".lb_section").animate({
        width: '100%',
        height: '1%',
    }, 500, function () {
        $(".lb_section").animate({
            height: '100%',
        }, 500, function () {});
    });

    $('#loading').delay(200).fadeOut(200);

});



$(function () {

    var currentColor = $('#loading').css('background-color');
    $('#loading').addClass('loading');

    $('#info_color').on("change", function () {
        currentColor = $('#loading').css('background-color');

        $('#loading').clone().insertBefore('#loading').addClass('dummyLoading');
        $('.dummyLoading').css('background-color', currentColor);
        $('.dummyLoading').css('z-index', 99999999999);

        e = this;
        $(".loading").fadeIn(200, function () {

            var href = $('.skin').attr('href');
            var lastSlash = href.lastIndexOf("/");
            var lastDot = href.lastIndexOf(".");
            var currentString = href.substring(lastSlash + 1, lastDot);
            var fixed = href.replace(currentString, e.value);

            $('.skin').attr('href', fixed);
            $('.dummyLoading').fadeOut(500).promise().done(function () {
                $('.dummyLoading').remove();
            });
        });
        setTimeout(function () {
            $(".loading").fadeOut(200);
        }, 1000);
    });
});


$('a, button').on('click', function (e) {
    var anchor = $(this),
        href = anchor.attr('href'),
        invalid = false;

    if (!anchor.hasClass("add") ||
        anchor[0].hasAttribute("target") ||
        anchor.hasClass("sf-dump-key") ||
        anchor.hasClass("sf-dump-index") ||
        anchor.hasClass("sf-dump-private") ||
        anchor.hasClass("sf-dump-protected") ||
        anchor.hasClass("sf-dump-ref") ||
        anchor.hasClass("sf-dump-expanded") ||
        anchor.hasClass("sf-dump-toggle") ||
        anchor.hasClass("next") ||
        anchor.hasClass("previous") ||
        anchor.hasClass("page_number") ||
        anchor.hasClass("decline") ||
        anchor.hasClass("accept") ||
        anchor.hasClass("mail") ||
        anchor.hasClass("signup") ||
        //        anchor.hasClass("finished") ||
        href == '#') {
        //DON'T DO ANIMATION
        return;
    } else {
        var i = 0;
        $('body').find('input').each(function () {
            if (invalid != true) {
                this.oninvalid = function (e) {
                    invalid = true;
                    return false;
                }
            }

            if (i >= $('body').find('input').length) {
                if (!invalid) {
                    preloader();
                }
            }
            i++
        });
    }
});

function preloader() {
    //FADE IN THE LOADING INTERFACE 
    $("#loading").fadeIn(200, function () {
        //ANIMATE IT FOR 200ms 
        setTimeout(function () {
            //THEN REDIRECT TO HREF IF IS A <a> 
            if (anchor.is('a')) {
                window.location = anchor.attr('href');
                //OR DO THIS IF IT'S A <button> 
            } else if (anchor.is('button')) {}
        }, 200);
        return false;
    });
}
